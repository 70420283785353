<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="about">
        </HeaderIntroImage>
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container">
                    <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p>
                    <div class="attachments-container">
                        <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    </div>
                </div>
            </section>
            <section class="main-content">
                <div class="container">

                    <p v-html="this.pageData.attributes.field_description_w_summary.value"></p>

                    <img class="image-banner" v-if="this.pageData.field_page_banner" :src="endpoint + this.pageData.field_page_banner.attributes.uri.url" alt="">

                    <div class="infographics">
                        <h2>BY THE NUMBERS</h2>
                        <div>
                            <div class="box main">
                                <div class="metadata">
                                    <p class="number">84 <span>million</span></p>
                                    <p class="desc">
                                        Forcibly displaced people worldwide
                                    </p>
                                    <p class="desc">
                                        While a full picture is yet to be established, UNHCR estimates that global
                                        forced displacement has surpassed 84 million at mid-2021.
                                    </p>
                                </div>
                                <div class="years">
                                    <span>2000</span>
                                    <span>2007</span>
                                    <span>2013</span>
                                    <span>2021</span>
                                </div>
                            </div>
                            <div class="small-boxes">

                                <div class="box">
                                    <div class="metadata">
                                        <p class="number">48 <span>million</span></p>
                                        <p class="desc">
                                            are <span>internally displaced people</span> (Source: IDMC, as of end-2020)
                                        </p>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="metadata">
                                        <p class="number">26.6 <span>million</span></p>
                                        <p class="desc">
                                            <span>are refugees</span> (as of mid-2021)
                                        </p>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="metadata">
                                        <p class="number">4.4 <span>million</span></p>
                                        <p class="desc">
                                            are <span>asylum-seekers</span> (as of mid-2021)
                                        </p>
                                    </div>
                                </div>
                                <div class="box">
                                    <div class="metadata">
                                        <p class="number">3.9 <span>million</span></p>
                                        <p class="desc">
                                            are <span>Venezuelans displaced abroad.</span> A total of 5.1 million
                                            Venezuelans have left their country as of mid-2021, including 186,800
                                            refugees,
                                            952,300 <span>asylum-seekers</span> and 3.9 million Venezuelans displaced
                                            abroad.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="refugee-statistics">
                            <div class="box outline afghanistan">
                                <h2>Afghanistan</h2>
                                <div>
                                    <p class="number">
                                        2.610.067
                                    </p>
                                    <p><span>Refugees</span> under UNHCR’s mandate</p>
                                </div>
                                <div>
                                    <p class="number">226.334</p>
                                    <p> <span>Asylum-seekers</span></p>
                                </div>
                            </div>
                            <div class="box outline myanmar">
                                <h2>Myanmar</h2>
                                <div>
                                    <p class="number">
                                        1.127.588
                                    </p>
                                    <p><span>Refugees</span> under UNHCR’s mandate</p>
                                </div>
                                <div>
                                    <p class="number">44.100</p>
                                    <p> <span>Asylum-seekers</span></p>
                                </div>
                            </div>
                            <div class="box outline south-sudan">
                                <h2>South Sudan</h2>
                                <div>
                                    <p class="number">
                                        2.277.919
                                    </p>
                                    <p><span>Refugees</span> under UNHCR’s mandate</p>
                                </div>
                                <div>
                                    <p class="number">3.355</p>
                                    <p> <span>Asylum-seekers</span></p>
                                </div>
                            </div>
                            <div class="box outline syria">
                                <h2>Syrian Arab Rep.</h2>
                                <div>
                                    <p class="number">
                                        6.761.560
                                    </p>
                                    <p><span>Refugees</span> under UNHCR’s mandate</p>
                                </div>
                                <div>
                                    <p class="number">117.390</p>
                                    <p> <span>Asylum-seekers</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <keep-alive v-if="this.pageData.field_ref_ui_text.length">
                        <p v-html="this.pageData.field_ref_ui_text[0].attributes.field_description_w_summary.value"></p>
                    </keep-alive>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import Attachments from '../components/Attachments.vue'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    import {
        fetchSinglePage
    } from '../libs/drupalClient'
    export default {
        name: 'aims-and-objectives',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "c0d239c8-1003-44cb-a986-795154403534"
            }
        },
        computed: {
            endpoint() {
                return process.env.VUE_APP_ENDPOINT
            },
        },
        methods: {},
        mounted() {
            fetchSinglePage(this.currentPageID, {
                    include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text', 'field_attachments','field_page_banner','field_page_images']
                })
                .then(res => {
                    this.pageData = res
                    console.log('pagedata', res)
                })
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

 
    .infographics {
        margin: 3rem 0;

        h2 {
            color: $green;
            font-weight: 400;
        }

        h4 {
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 2rem;
        }



        .box {
            background-color: #030D26;
            border-radius: 10px;
            padding: 2rem 2rem;
            margin-bottom: 1rem;

            &.main {
                padding: 2.8rem 2rem 60% 2rem;
                background-image: url("../assets/chart-1.png");
                background-size: contain;
                background-repeat: no-repeat;
                justify-content: space-between;
                display: flex;
                flex-direction: column;
                background-position: center bottom;
                position: relative;

                &:after {
                    content: "Data source: UNHCR";
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: rgba(255, 255, 255, 0.705);
                    font-style: italic;
                    font-size: 12px;
                    margin: 14px;
                }

                .number {
                    font-size: 4.6rem;
                    margin: 0;
                    line-height: 60px;

                    span {
                        font-size: 1.8rem;
                    }
                }

                .years {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    bottom: 20px;
                    position: absolute;
                    width: calc(100% - 4rem);
                    left: 2rem;

                    span {
                        color: #fff;
                        font-size: 14px;
                    }
                }

                p:not(.number):nth-child(2) {
                    color: #fff;
                    font-weight: 600;
                    margin-bottom: 10px;
                    font-size: 1.5rem;
                }
            }

            .number {
                color: $orange;
                font-weight: 500;
                font-size: 2rem;
                margin: 0;

                span {
                    font-size: 1.6rem;
                }
            }

            .desc {
                margin: 0;
                color: #fff;

                span {
                    color: $orange;
                    font-weight: 700;
                }
            }

            .map {
                position: relative;
                min-height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 2rem;

                &:after {
                    width: 100%;
                    height: 100%;
                    content: "";
                    position: absolute;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            .title-country {
                text-transform: uppercase;
                color: #fff;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .box.outline {
            background-color: transparent;
            border: 4px solid #030D26;
            padding: 2rem 2rem 1rem 2rem;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            &.afghanistan {
                background-image: url("../assets/afgh.png");
            }

            &.syria {
                background-image: url("../assets/syria.png");
            }

            &.myanmar {
                background-image: url("../assets/mynmr.png");
            }

            &.south-sudan {
                background-image: url("../assets/south.png");
            }

            h2 {
                margin-bottom: 1rem;
                font-size: 20px;
                color: #030D26;
                font-weight: 500;
                text-transform: uppercase;
            }

            .number {
                font-weight: 600;
                color: $orange;
            }

            p {
                margin-top: 0;
                color: #030D26;

                span {
                    font-weight: 600;
                }
            }
        }

    }

    @media (min-width:1024px) {
        .infographics .afghanistan .map:after {
            transform: scale(1.6);
        }

        .infographics {
            max-width: 1030px;
            margin: 2rem auto;

            >div {
                max-width: none;
                margin: 2rem auto;
                justify-content: space-between;
                display: flex;
            }
        }

        .infographics {
            .box:not(.main) {
                padding: 1.5rem 2rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                max-width: 580px;
                width: 580px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                .metadata {
                    display: flex;
                    align-items: center;

                    .number {
                        font-size: 40px;
                        line-height: 2rem;
                        margin-right: 2rem;
                        margin-bottom: 0rem;
                        width: 20%;

                        span {
                            font-size: 20px;
                        }
                    }

                    .desc {
                        font-size: 16px;

                    }
                }

                .map {
                    min-width: 200px;
                    width: 200px;
                    margin-bottom: 0;
                    max-height: 200px;
                }
            }

            .box.main {
                width: 46%;
                padding: 5rem 2rem 2rem 2rem;
                max-width: none;
                margin-bottom: 0;


                .metadata {
                    flex-direction: column;
                    align-items: flex-start;

                    .desc {
                        margin-left: 0rem;
                    }
                }

                .number {
                    margin-bottom: 2rem;
                    font-size: 6rem;

                    span {
                        font-size: 2rem;
                    }
                }

            }

            .small-boxes {
                width: 46%;

                .box {
                    width: auto;
                }
            }

            .refugee-statistics {
                justify-content: space-between;
                align-items: stretch;
            }

            .box.outline {
                flex-direction: column;
                max-width: none;
                width: 17%;
                margin-bottom: 0px;

                p:not(.number) {
                    margin: 0rem auto 1rem;
                }

                .number {
                    font-weight: 600;
                    font-size: 1.75rem;
                }
            }
        }
    }
</style>